module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Tuatam Studio","short_name":"Tuatam","start_url":"/","icon":"src/assets/icon.png","background_color":"#f7f0eb","theme_color":"#a2466c","display":"standalone","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"623caca860e6d72300fdf37ad3fd6542"},
    },{
      plugin: require('../node_modules/gatsby-plugin-matomo/gatsby-browser.js'),
      options: {"plugins":[],"siteId":"YOUR_SITE_ID","matomoUrl":"https://YOUR_MATOMO_URL.COM","siteUrl":"https://YOUR_LIVE_SITE_URL.COM"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
